<template>
  <div class="set-target-enterprise-wrapper">
    <form-panel ref="formPanel"
                v-bind="submitConfig"
                :form="form"
                :submitBefore="submitBefore"
                @update="update">
      <col2-detail>
        <col2-block title="基础信息">
          <el-form-item label="所属项目"
                        :rules="{ required: true, message: '请选择所属项目', trigger:'change'}"
                        prop="communityId">
            <v-select2 :width="width"
                       v-model="form.communityId"
                       placeholder="查询项目"
                       v-bind="communityParams"
                       @onChange="onCommunityChange" />
          </el-form-item>
          <el-form-item>
            <div> <i class="el-icon-warning"></i> 系统将依据你设置的条件要求，自动计算企业与园区的匹配程度</div>
          </el-form-item>
          <el-form-item label="行业类别">
            <checkbox-plus type="default"
                           mode="checkbox-plus"
                           :options="opsMap.ENTERPRISE_INDUSTRY_TYPE"
                           :value.sync="form.industryType" />
          </el-form-item>
          <!-- <el-form-item>
            <el-divider></el-divider>
          </el-form-item> -->
          <el-form-item label="企业人数">
            <checkbox-plus type="default"
                           mode="checkbox-plus"
                           :options="opsMap.ENTERPRISE_NUM"
                           :value.sync="form.enterpriseNum" />
          </el-form-item>
          <el-form-item label="战略性新兴产业">
            <checkbox-plus type="default"
                           mode="checkbox-plus"
                           :options="opsMap.ENTERPRISE_NEW_INDUSTRY"
                           :value.sync="form.enterpriseNewIndustry" />
          </el-form-item>
          <el-form-item label="纳税人类型">
            <checkbox-plus type="default"
                           mode="checkbox-plus"
                           :options="opsMap.ENTERPRISE_TAXPAYER_TYPE"
                           :value.sync="form.taxpayerType" />
          </el-form-item>
          <el-form-item label="企业评级">
            <checkbox-plus type="default"
                           mode="checkbox-plus"
                           :options="opsMap.ENTERPRISE_LEVEL"
                           :value.sync="form.enterpriseLevel" />
          </el-form-item>
          <el-form-item label="企业类型">
            <checkbox-plus type="default"
                           mode="checkbox-plus"
                           :options="opsMap.ENTERPRISE_TYPE"
                           :value.sync="form.enterpriseType" />
          </el-form-item>
          <el-form-item label="注册资本">
            <v-input-number v-model="form.registerCapitalBegin" />
            ~
            <v-input-number v-model="form.registerCapitalEnd" />万元
          </el-form-item>
          <el-form-item label="风险预估">
            <checkbox-plus type="default"
                           mode="checkbox-plus"
                           :options="opsMap.ENTERPRISE_RISK_ASSESSMENT"
                           :value.sync="form.riskAssessment" />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import Vue from 'vue'
import { Divider } from 'element-ui'
import { setTargetURL, queryDictURL, queryTargetURL } from './api'
import { communityParams } from 'common/select2Params'
import { Col2Detail, Col2Block, CheckboxPlus } from 'components/bussiness'
import _ from 'lodash'
Vue.use(Divider)
const SubmitKeys = [
  'industryType',
  'enterpriseNum',
  'enterpriseNewIndustry',
  'taxpayerType',
  'enterpriseLevel',
  'enterpriseType',
  'riskAssessment'
]
export default {
  name: 'SetTargetEnterprise',
  components: {
    Col2Detail,
    Col2Block,
    CheckboxPlus
  },
  data () {
    return {
      communityParams,
      width: 200,
      form: {
        communityId: undefined,
        industryType: [],
        enterpriseNum: [],
        enterpriseNewIndustry: [],
        taxpayerType: [],
        enterpriseLevel: [],
        enterpriseType: [],
        registerCapitalBegin: undefined,
        registerCapitalEnd: undefined,
        riskAssessment: []
      },
      submitConfig: {
        submitUrl: setTargetURL
      },
      opsMap: {
        ENTERPRISE_NUM: [],
        ENTERPRISE_INDUSTRY_TYPE: [],
        ENTERPRISE_NEW_INDUSTRY: [],
        ENTERPRISE_TAXPAYER_TYPE: [],
        ENTERPRISE_LEVEL: [],
        ENTERPRISE_TYPE: [],
        ENTERPRISE_RISK_ASSESSMENT: []
      }
    }
  },
  created () {
    this.$setBreadcrumb('设置匹配条件')
  },
  mounted () {
    this.fetchOptions()
  },
  methods: {
    update (data) {
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },
    async submitBefore (data) {
      let isConfirm = await this.$confirm('将以所设企业画像计算匹配程度，请确认操作')
      if (isConfirm) {
        let isEmpty = _.every(SubmitKeys, key => data[key].length === 0)
        if (isEmpty) {
          this.$message('当前所设目标为空，无法保存')
          return false
        }
        SubmitKeys.forEach(key => {
          data[key] = data[key].join(',')
        })
        return true
      }
    },
    // 查询下拉选项
    async fetchOptions () {
      let { data } = await this.$axios.get(queryDictURL, {
        params: {
          typeValueList: [
            'ENTERPRISE_NUM',
            'ENTERPRISE_INDUSTRY_TYPE',
            'ENTERPRISE_NEW_INDUSTRY',
            'ENTERPRISE_TAXPAYER_TYPE',
            'ENTERPRISE_LEVEL',
            'ENTERPRISE_TYPE',
            'ENTERPRISE_RISK_ASSESSMENT'
          ].join(',')
        }
      })
      if (data) {
        Object.keys(data).forEach(key => {
          this.opsMap[key] = data[key].map(({ id, name }) => ({ label: name, value: id }))
        })
      }
    },
    async onCommunityChange (item) {
      if (this.form.communityId) {
        let { data } = await this.$axios.get(queryTargetURL, { params: { communityId: this.form.communityId } })
        SubmitKeys.forEach(key => {
          this.form[key] = data[key] ? data[key].split(',').map(num => Number(num)) : []
        })
        this.form.registerCapitalBegin = data.registerCapitalBegin
        this.form.registerCapitalEnd = data.registerCapitalEnd
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.set-target-enterprise-wrapper {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}
</style>
