var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "set-target-enterprise-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: {
                          required: true,
                          message: "请选择所属项目",
                          trigger: "change",
                        },
                        prop: "communityId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              width: _vm.width,
                              placeholder: "查询项目",
                            },
                            on: { onChange: _vm.onCommunityChange },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", [
                    _c("div", [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _vm._v(
                        " 系统将依据你设置的条件要求，自动计算企业与园区的匹配程度"
                      ),
                    ]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "行业类别" } },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "default",
                          mode: "checkbox-plus",
                          options: _vm.opsMap.ENTERPRISE_INDUSTRY_TYPE,
                          value: _vm.form.industryType,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "industryType", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业人数" } },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "default",
                          mode: "checkbox-plus",
                          options: _vm.opsMap.ENTERPRISE_NUM,
                          value: _vm.form.enterpriseNum,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "enterpriseNum", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "战略性新兴产业" } },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "default",
                          mode: "checkbox-plus",
                          options: _vm.opsMap.ENTERPRISE_NEW_INDUSTRY,
                          value: _vm.form.enterpriseNewIndustry,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.form,
                              "enterpriseNewIndustry",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "纳税人类型" } },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "default",
                          mode: "checkbox-plus",
                          options: _vm.opsMap.ENTERPRISE_TAXPAYER_TYPE,
                          value: _vm.form.taxpayerType,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "taxpayerType", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业评级" } },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "default",
                          mode: "checkbox-plus",
                          options: _vm.opsMap.ENTERPRISE_LEVEL,
                          value: _vm.form.enterpriseLevel,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "enterpriseLevel", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业类型" } },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "default",
                          mode: "checkbox-plus",
                          options: _vm.opsMap.ENTERPRISE_TYPE,
                          value: _vm.form.enterpriseType,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "enterpriseType", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "注册资本" } },
                    [
                      _c("v-input-number", {
                        model: {
                          value: _vm.form.registerCapitalBegin,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "registerCapitalBegin", $$v)
                          },
                          expression: "form.registerCapitalBegin",
                        },
                      }),
                      _vm._v("\n          ~\n          "),
                      _c("v-input-number", {
                        model: {
                          value: _vm.form.registerCapitalEnd,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "registerCapitalEnd", $$v)
                          },
                          expression: "form.registerCapitalEnd",
                        },
                      }),
                      _vm._v("万元\n        "),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "风险预估" } },
                    [
                      _c("checkbox-plus", {
                        attrs: {
                          type: "default",
                          mode: "checkbox-plus",
                          options: _vm.opsMap.ENTERPRISE_RISK_ASSESSMENT,
                          value: _vm.form.riskAssessment,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "riskAssessment", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }